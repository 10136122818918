



































import Vue from 'vue'
import {
  mapState,
  mapGetters,
  mapActions
} from 'vuex'
import Loading from '@/components/Loading/index.vue'
import PromocodeForm from './PromocodeForm.vue'
import PromocodesList from './PromocodesList.vue'
import Add from '@/views/ProfileDetail/components/Add.vue'
import { Promocode } from '@/store/modules/promocodes/types'
import { PAGINATION_LIMIT } from '@/common/constants'

export default Vue.extend({
  name: 'CurrentPromocodes',

  components: {
    Loading,
    Add,
    PromocodeForm,
    PromocodesList
  },

  props: {
    profileId: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      offset: 0,
      limit: PAGINATION_LIMIT,
      editingPromocode: null as Promocode | null,
      loading: true,
      promocodes: [],
      fields: [
        {
          key: 'description',
          label: 'Название'
        },
        {
          key: 'type',
          label: 'Тип промокода'
        },
        {
          key: 'discount_amount',
          label: 'Размер скидки, KGS.'
        },
        {
          key: 'created_at',
          label: 'Дата создания'
        },
        {
          key: 'code',
          label: 'Промокод'
        },
        {
          key: 'actions',
          label: '',
          thStyle: { width: '1%' }
        }
      ]
    }
  },

  computed: {
    ...mapState('profiles', [
      'profile'
    ]),

    ...mapGetters('promocodes', [
      'getNewPromocode'
    ])
  },

  watch: {
    $route () {
      this.offset = Number(this.$route.query.offset || 0)
    },

    offset () {
      this.getPromocodes()
    }
  },

  mounted () {
    this.offset = Number(this.$route.query.offset || 0)
    this.getPromocodes()
  },

  methods: {
    ...mapActions('promocodes', [
      'getProfilePromocodes',
      'archivePromocode'
    ]),

    async getPromocodes () {
      try {
        this.loading = true
        const res = await this.getProfilePromocodes({
          profileId: this.profileId || this.profile?.profile_data?.id || null,
          isArchived: false,
          limit: this.limit,
          offset: this.offset
        })
        this.promocodes = res
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    },

    handleCreatePromocode () {
      this.editingPromocode = this.getNewPromocode()
      this.$bvModal.show('modal-edit-promocode')
    },

    handleArchivePromocode (promocodeId: string) {
      this.$bvModal.msgBoxConfirm('Это действие нельзя отменить', {
        title: 'Вы действительно хотите архивировать этот промокод?',
        text: 'Это действие нельзя отменить',
        size: 'sm',
        hideHeaderClose: false,
        // okVariant: 'danger',
        okTitle: 'Архивировать',
        cancelTitle: 'Отмена',
        headerClass: 'text-center',
        bodyClass: 'text-center',
        footerClass: 'p-2 justify-content-center',
        centered: true
      })
        .then(value => {
          if (value) this.confirmArchiveProduct(promocodeId)
        })
        .catch(err => {
          console.log(err)
        })
    },

    async confirmArchiveProduct (promocodeId: string) {
      try {
        this.loading = true
        await this.archivePromocode(promocodeId)
        this.$toast.success('Промокод архивирован')
        this.$bvModal.hide('modal-edit-promocode')
        this.getPromocodes()
      } catch {
        this.$toast.error('Произошла ошибка при архивации промокода')
      } finally {
        this.loading = false
      }
    },

    showPromocodeDetails (promocode: any) {
      this.editingPromocode = promocode
      this.$bvModal.show('modal-edit-promocode')
    }
  }
})
